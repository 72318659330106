import { css } from "@emotion/css";
import { cssBreakpoint } from "../../styles/theme";

export const TeamPageCSS = css`
  width: 100%;
  padding: 40px 40px;
  color: #8f8f8f;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
    padding: 40px 20px 40px 20px;
  }

  &--Mission {
    font-size: 10px;
    justify-content: space-between;
  }

  &--title {
    font-size: 16px;
    padding: 20px ;
    justify-content: space-between;
    flex-direction: column;
  }

  &--portrait-container {
    padding: 0px;
    display: flex;
    flex-direction: row;
    max-width: 800px;
    margin-top: 20px;
    gap: 10px;
  }

  &--portrait {
    flex-direction: column;
    padding: 30px 0px;
    gap: 20px;
    background-color: #fafafa;
    border-radius: 10px;
    height: 200px;
    justify-content: center;
    &:hover {
    background-color: #ebebeb;
    color: white;
  }
  &:active {
    background-color: #ebebeb;
    color: white;
  }
  }

  &--portrait-title {
      font-size: 16px;
      padding: 0px 20px;
      gap: 5px;
      flex-direction: column;
      justify-content: start;
      &:hover{
        color: white;
      }
  }

  &--portrait-image {
    padding: 0px 20px;
    @media ${cssBreakpoint.down("md")} {
    justify-content: center;
    max-width: 400px;
  }
  }

  &--description {
    max-width: 800px;
    flex-direction: column;
    padding: 0px 20px;
  }
`;

export const ComingSoonCSS = css`
  font-size: 100px;
  @media ${cssBreakpoint.down("md")} {
    font-size: 50px;
  }
`;

export const EducationCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
  }
`;

export const InformationCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
  }
`;

export const WorkExperienceCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
  }
`;
