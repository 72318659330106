import { Container } from "../../base/Container/Container";
import { TeamPageCSS } from "./TeamPage.style";
import { Text } from "../../base/Text/Text";
import classNames from "classnames";
import { MouseEvent } from "react";
import { routes } from "../../routes";
import { Image } from "../../base/Image";
import { useNavigate } from "react-router-dom";
import { Button } from "../../base/Button"

export function Ro() {

  const navigate = useNavigate()

  const handleClickPrev = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.team);
  }

  return (
    <Container
      className={TeamPageCSS}
      column
      width="100%"
      height="100%"
      alignItems="center"
    >

<Button onClick={handleClickPrev}>
     <Image
      style={{ width: "25px", marginLeft: "20px"}}
      src="/assets/arrow-left-55.svg"
      />
    </Button>
      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        <text>JAEHUN RO 노재헌</text>
        <text>CEO, Co-Founder</text>
      </Text>

      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--description`)}
      >
        <text>
        노재헌 대표는 법률전문가입니다. 
        </text>
        <text>
        그는 민간에서 한국의 문화외교산업을 발전시키는데 큰 이바지를 해오기도 했습니다. 동아시아문화센터를 설립하여, 한국-중국-일본의 상호교류와 문화창달에 힘썼습니다. 
        </text>
        <text>
        감기획에서 노재헌 대표는 비즈니스의 안정적인 확장과 아시아시장에서의 입지 확보를 위해 핵심적인 역할을 수행하고 있습니다. 
        </text>
      </Text>

      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        <text>미국변호사</text>
        <text>조지타운대학교 법학 박사</text>
        <text>동아시아문화센터 원장</text>
        <text></text>
      </Text>

      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        <text>jro@pclglobal.xyz</text>
      </Text>





 

    </Container>
  );
}