import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { Container } from "../../base/Container/Container";
import { DesktopHeader } from "./DesktopHeader";
import { headerContainerCSS } from "./Header.style";
import { MobileHeader } from "./MobileHeader";
// import { UseOverContent } from "./UseOverContent";
import { useScrollDirection } from "../../hook/useScrollDirection";
import { cssBreakpoint } from "../../styles/theme";
import { Tab } from "../../types/header";

const tabList: Tab[] = [
  {
    label: "About",
    disabled: false,
  },
  { label: "Contact", disabled: true },
  { label: "Language", disabled: true },
];

function useHeader() {
  const scrollDirection = useScrollDirection();
  const scrollDown = scrollDirection === "down";

  const isMd = useMediaQuery({ query: cssBreakpoint.down("md") });

  return {
    scrollDown,
    isMd,
  };
}

export function Header() {
  const { scrollDown, isMd } = useHeader();

  return (
    <Container
      className={classNames(
        headerContainerCSS,
        scrollDown && `${headerContainerCSS}--scrollDown`
      )}
      width="100%"
      justifyContent="space-between"
    >
      {isMd ? (
        <MobileHeader tabList={tabList} />
      ) : (
        <DesktopHeader tabList={tabList} />
      )}
    </Container>
  );
}
