import { css } from "@emotion/css";

const directionStyle = `
  &.row {
    flex-direction: row;
  }

  &.row.reverse {
    flex-direction: row-reverse;
  }

  &.column {
    flex-direction: column;
  }

  &.column.reverse {
    flex-direction: column-reverse;
  }
`;

export const containerCSS = css`
  display: flex;

  width: fit-content;
  height: fit-content;

  ${directionStyle}
`;
