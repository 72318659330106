import { CSSProperties, DetailedHTMLProps, HTMLAttributes, ReactNode, useMemo } from "react";

import { Span } from "./Text.style";

const TextTags = ["span", "h1", "h2", "h3", "h4", "h5", "h6", "p"] as const;

type TextTag = typeof TextTags[number];

export type TextProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
  tag?: TextTag;
  color?: CSSProperties["color"];
  textAlign?: CSSProperties["textAlign"];
  textTransform?: CSSProperties["textTransform"];
  textDecoration?: CSSProperties["textDecoration"];
};

function applyLineBreak(children: ReactNode) {
  if (!(typeof children === "string")) return children;

  const tokens = children.split("\\n");

  return tokens.length === 1
    ? tokens
    : tokens.map((c, i) => (
        <Span css={{ display: "block" }} key={`${i}`}>
          {c}
        </Span>
      ));
}

export function Text({
  tag,
  color,
  textAlign,
  textTransform,
  textDecoration,
  children: _children,
  ...props
}: TextProps) {
  const children = useMemo(() => applyLineBreak(_children), [_children]);

  return (
    <Span
      as={tag ?? "span"}
      css={{
        color,
        textAlign: textAlign !== "revert-layer" ? textAlign : undefined,
        textTransform: textTransform !== "revert-layer" ? textTransform : undefined,
        textDecoration,
      }}
      {...props}
    >
      {children}
    </Span>
  );
}
