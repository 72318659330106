import { ReactEventHandler, SyntheticEvent, useCallback } from "react";

function useImageExtension(src: string | undefined) {
  if (!src) return undefined;

  const ext = src.split(".").pop();
  return ext && ext.toLowerCase();
}

export function useImage(
  src: string | undefined,
  fallback: string | undefined,
  onError: ReactEventHandler<HTMLImageElement> | undefined
) {
  const ext = useImageExtension(src);
  const isSVG = ext === "svg";

  const handleError = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      if (onError) onError(e);
      else if (fallback) {
        e.currentTarget.src = fallback;
      }
    },
    [onError]
  );

  return { isSVG, handleError };
}
