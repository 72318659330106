import { css } from "@emotion/css";

export const ButtonCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: fit-content;
`;
