import { MouseEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../base/Button";
import { Container } from "../../base/Container";
import { Image } from "../../base/Image";
import {
  desktopHeaderContentCSS,
  desktopHeaderCSS,
  desktopHeaderLogoCSS,
} from "./DesktopHeader.style";
import { routes } from "../../routes";
import { Tab } from "@/types/header";

function useDesktopHeader() {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [selectedTab, setSelectedTab] = useState<number>();

  const handleClickMainLogo = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.home);
  };

  const handleClickAbout = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.about);
  };

  const handleClickTeam = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.team);
  };

  const handleClickInsights = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.insights);
  };

  const handleClickNews = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.news);
  };

  return {
    containerRef,
    // selectedTab,
    handleClickMainLogo,
    handleClickAbout,
    handleClickTeam,
    handleClickInsights,
    handleClickNews,
    // handleClickTab,
  };
}

interface DesktopHeaderProps {
  tabList: Tab[];
}

export function DesktopHeader({ tabList }: DesktopHeaderProps) {
  const {
    containerRef,
    handleClickMainLogo,
    handleClickAbout,
    handleClickTeam,
    handleClickInsights,
    handleClickNews,
  } = useDesktopHeader();
  console.log(tabList);

  return (
    <Container column className={desktopHeaderCSS} ref={containerRef}>
      <Container
        row
        className={desktopHeaderContentCSS}
        justifyContent="space-between"
        alignItems="center"
      >
        <Button onClick={handleClickMainLogo}>
          <Image className={desktopHeaderLogoCSS} src="/assets/Logo1.svg" />
        </Button>

        <Container style={{ alignItems: "center", gap: "20px" }}>
          <Button style={{ fontWeight: "600" }} onClick={handleClickAbout}>
            About
          </Button>

          <Button style={{ fontWeight: "600" }} onClick={handleClickTeam}>
            Team
          </Button>

          <Button style={{ fontWeight: "600" }} onClick={handleClickInsights}>
            Insights
          </Button>

          <Button style={{ fontWeight: "600" }} onClick={handleClickNews}>
            News
          </Button>

          <Container style={{ gap: "10px" }}>
            {/* <Button
              onClick={() => {
                window.open("https://www.instagram.com/u_youha/");
              }}
            >
              <Image
                style={{ width: "25px" }}
                src="/assets/social/instagram.svg"
              />
            </Button>
            <Button
              onClick={() => {
                window.open("https://ko-kr.facebook.com/profile.php?id=pfbid034P5r9FnDiTDo1oVikyiU6Hh9hnc31QozJ2LyoFyXrh9dKz6iSocTyktePyVDKR5gl");
              }}
            >
              <Image
                style={{ width: "25px"}}
                src="/assets/social/facebook.svg"
              />
            </Button> */}
          </Container>
        </Container>

        {/* <Container row gap="32px" style={{gap: "28px"}}>
          {tabList.map((tab, i) => {
            const { disabled } = tab;
            return (
              <Button
                key={tab.label}
                className={classNames(
                  desktopTabButtonCSS,
                   i === selectedTab && `${desktopTabButtonCSS}--selected`
                )}
                disabled={disabled}
                onClick={() => !disabled && handleClickTab(i)}
              >
                {tab.label}
              </Button>
            );
          })}
        </Container> */}
      </Container>
    </Container>
  );
}
