import { Container } from "../../base/Container/Container";
import { AboutPageCSS } from "../About/AboutPage.style";
import { Text } from "../../base/Text/Text";
import classNames from "classnames";

export function AboutPage() {
  return (
    <Container
      className={AboutPageCSS}
      column
      width="100%"
      height="100%"
      alignItems="center"
    >
      <Text
        className={classNames(AboutPageCSS, `${AboutPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        감기획과 비전을 소개합니다.
      </Text>
      <Text
        className={classNames(AboutPageCSS, `${AboutPageCSS}--description`)}
      >
        감기획은 문화산업의 새로운 패러다임을 제시하고자 합니다. 우리는 최신
        정보통신기술을 활용하여 콘텐츠 제작, 배포, 관리를 획기적으로
        변화시키고자 합니다. 그 과정에서 한국, 중국, 일본 등 아시아 시장을
        중심으로 세계적인 문화산업의 선도적 위치를 확보하고자 합니다.
      </Text>

      <Text
        className={classNames(AboutPageCSS, `${AboutPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        감기획의 사업분야를 소개합니다.
      </Text>
      <Text
        className={classNames(AboutPageCSS, `${AboutPageCSS}--description`)}
      >
        감기획은 다양한 분야에서 사업을 진행하고 있습니다. 이에는 IP 라이센싱 및
        수권보호, 아티스트 교류, 공연, 전시, 문화콘텐츠 사업, 블록체인을 활용한
        콘텐츠 거래 등이 포함됩니다. 특히 우리는 한국-중국-일본 간의 문화 교류와
        비즈니스 확장을 통해 아시아 시장에서 중요한 위치를 차지하고자 합니다.
        이를 통해 감기획은 문화산업의 다양한 분야에서 혁신적인 변화를 주도하고자
        합니다.
      </Text>

      <Text
        className={classNames(AboutPageCSS, `${AboutPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        감기획과의 협업방법을 안내드립니다.
      </Text>
      <Text
        className={classNames(AboutPageCSS, `${AboutPageCSS}--description`)}
      >
        감기획은 여러분의 아이디어와 열정을 기다립니다. 문화콘텐츠 산업의 융성과
        관련한 모든 기획업무에 열려있으며, 여러분의 독특한 아이디어와 저희의
        전문성을 결합하여 새로운 문화의 획을 그을 수 있는 기회를 공유하고자
        합니다. 협업 제안은 contact@pclglobal.xyz로 보내주시면 됩니다. 감기획과
        함께 문화 산업의 미래를 함께 창조해 나가는 데 참여하실 분들의 많은
        연락을 기다리고 있습니다.
      </Text>
    </Container>
  );
}
