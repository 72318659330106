import { css } from "@emotion/css";
import { colorPalette, cssBreakpoint, typography } from "../styles/theme";

export const pageCSS = css`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
`;

export const pageBodyCSS = css`
  width: 100%;
  margin-top: 56px;
`;

export const FooterSectionCSS = css`
  width: 100%;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
    padding: 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: none;
  }
`;

export const FooterLogoCSS = css`
  width: 100%;
  gap: 9px;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 11px;
  color: #c2c2c2;
  @media ${cssBreakpoint.down("md")} {
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 28px 0px 16px 0px;
    border-top: 1px solid ${colorPalette.overGray100};
  }
`;

export const FooterButtonCSS = css`
  ${typography.b2};

  min-width: 150px;

  @media ${cssBreakpoint.down("md")} {
    font-size: 14px;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 24px;
  }
`;

export const mobileFooterSocialCSS = css`
  gap: 10px;
  padding: 20px 20px 28px 20px;
`;
