import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { SVGComponentProps } from "./Image.type";

export const SVGContainerCSS = css`
  display: flex;
`;

export const SVGMaskComponent = styled.svg<SVGComponentProps>`
  background-color: ${({ color }) => color};

  -webkit-mask-image: url(${({ url }) => url});
  mask-image: url(${({ url }) => url});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
`;
