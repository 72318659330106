import { css } from "@emotion/css";
import { moveLeftIn, moveRightOut } from "../../styles/animation";
import { colorPalette, typography } from "../../styles/theme";

export const mobileHeaderCSS = css`
  width: 100%;
  background-color: ${colorPalette.overWhite};
  z-index: 1000;
`;

export const mobileHeaderTitleCSS = css`
  width: 100%;
  padding: 20px 16px;
  justify-content: space-between;
  align-items: center;
`;

export const mobileHeaderContentCSS = css`
  width: 100%;
  height: calc(100vh - 62px);
  background-color: ${colorPalette.overWhite};

  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-name: ${moveLeftIn};

  &--hide {
    animation-name: ${moveRightOut};
  }
`;

export const mobileTapButtonCSS = css`
  ${typography.h5}
  padding: 20px 16px;
  width: 100%;
  color: ${colorPalette.overBlack};

  :disabled {
    cursor: unset;
    color: ${colorPalette.overGray400};
  }
`;

export const mobileHeaderLogoCSS = css`
  width: 220px;
`;

export const mobileHeaderWrapperCSS = css`
  width: 100%;
`;

export const mobileHeaderButtonCSS = css`
  padding: 20px;
`;
