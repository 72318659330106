import { css } from "@emotion/css";
import { colorPalette, cssBreakpoint } from "../../styles/theme";

export const HeaderCSS = css`
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const HeaderLogoCSS = css`
  width: 250px;
  justify-content: flex-start;
  @media ${cssBreakpoint.down("md")} {
    padding: 20px;
    width: 150px;
  }
`;

export const headerContainerCSS = css`
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colorPalette.overWhite};
  z-index: 1000;
  transition: all 200ms ease-in-out;

  &--scrollDown {
    transform: translateY(-100%);
  }

  @media ${cssBreakpoint.down("md")} {
    background-color: unset;
  }
`;

export const overLabsLogoCSS = css`
  padding: 10px;
`;

export const externalLinkContainerCSS = css`
  width: 100%;
  padding: 16px 60px;
  background-color: ${colorPalette.overGray50};
`;
