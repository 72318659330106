import { css } from "@emotion/css";
import { cssBreakpoint } from "../../styles/theme";
import { typewriter } from "../../styles/animation";

export const HomePageCSS = css`
  width: 100%;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
    padding: 100px 20px 40px 20px;
  }
`;

export const HomePageTitleCSS = css`
  color: black;
  font-size: 100px;
  font-weight: 700;
  width: fit-content;
  position: relative;

  @media ${cssBreakpoint.down("md")} {
    font-size: 55px;
  }

  &--1 {
    &::before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:before {
      background: black;
      animation: ${typewriter} 0.7s ease forwards;
    }
  }

  &--2 {
    &::before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:before {
      background: black;
      animation: ${typewriter} 1s ease forwards;
      animation-delay: 1s;
    }
  }

  &--3 {
    &::before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:before {
      background: black;
      animation: ${typewriter} 1.5s ease forwards;
      animation-delay: 2s;
    }
  }
  /* &--1{
  background: black;
animation: ${typewriter} 1s ease forwards;
}
&--2{
  background: black;
animation: ${typewriter} 2s ease forwards;
}
&--3{
  background: black;
animation: ${typewriter} 3s ease forwards;
} */
`;
