import { Global, css } from "@emotion/react";
import { resetStyles } from "./reset";

const styles = css`
  ${resetStyles}

  @font-face {
    font-family: "Prompt";
    font-style: normal;
    src: url("/fonts/prompt/prompt-v10-latin-regular.woff2") format("woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Secular One";
    font-style: normal;
    src: url("/fonts/Secular_One/secular-one-v11-latin-regular.woff2")
      format("woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Krona One";
    font-style: normal;
    src: url("/fonts/KronaOne/webfonts/krona-one-v14-latin-regular.woff2")
      format("woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Manrope";
    src: url("/fonts/manrope/webfonts/Manrope-Light.woff2") format("woff2");
    font-weight: 300;
  }

  @font-face {
    font-family: "Manrope";
    src: url("/fonts/manrope/webfonts/Manrope-Regular.woff2") format("woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Manrope";
    src: url("/fonts/manrope/webfonts/Manrope-Medium.woff2") format("woff2");
    font-weight: 500;
  }

  @font-face {
    font-family: "Manrope";
    src: url("/fonts/manrope/webfonts/Manrope-Bold.woff2") format("woff2");
    font-weight: 700;
  }

  body {
  }

  * {
    font-family: "Prompt", "Secular One", "Krona One", "Manrope", sans-serif;
    box-sizing: border-box;
  }
`;

export function GlobalStyles() {
  return <Global styles={styles} />;
}
