import { Container } from "../../base/Container/Container";
import { HomePageCSS, HomePageTitleCSS } from "./HomePage.style";
import { Text } from "../../base/Text/Text";
import classNames from "classnames";

export function HomePage() {
  return (
    <Container
      className={HomePageCSS}
      column
      width="100%"
      height="100%"
      alignItems="center"
    >
      <Text className={classNames(HomePageTitleCSS, `${HomePageTitleCSS}--1`)}>
        {" "}
        감기획,
      </Text>
      <Text className={classNames(HomePageTitleCSS, `${HomePageTitleCSS}--2`)}>
        {" "}
        柿子企划,
      </Text>
      <Text className={classNames(HomePageTitleCSS, `${HomePageTitleCSS}--3`)}>
        {" "}
        PERSIMMON.
      </Text>
    </Container>
  );
}
