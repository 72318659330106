import classNames from "classnames";
import { forwardRef } from "react";
import { ButtonCSS } from "./Button.style";
import { ButtonProps } from "./Button.type";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, width, height, ...props }, ref) => (
    <button className={classNames(ButtonCSS, className)} {...props} css={{ width, height }} ref={ref} />
  )
);

Button.displayName = "Button";
