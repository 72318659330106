import { css } from "@emotion/css";
import { cssBreakpoint } from "../../styles/theme";

export const AboutPageCSS = css`
  width: 100%;
  padding: 40px 40px;
  color: #8f8f8f;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  max-width: 800px;
  @media ${cssBreakpoint.down("md")} {
    padding: 40px 20px 40px 20px;
  }

  &--title {
    font-size: 16px;
    padding: 20px ;
    justify-content: space-between;
  }

  &--description {
    padding: 0px 20px;
    font-size: 14px;
  }
`;

export const EducationCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  gap: 12px;
  @media ${cssBreakpoint.down("md")} {
    padding: 40px 0px 0px 0px;
  }
`;

export const InformationCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  gap: 12px;
  @media ${cssBreakpoint.down("md")} {
    padding: 40px 0px 0px 0px;
  }
`;

export const WorkExperienceCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  gap: 12px;
  @media ${cssBreakpoint.down("md")} {
    padding: 40px 0px 0px 0px;
  }
`;

export const ContactCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  gap: 12px;
  @media ${cssBreakpoint.down("md")} {
    padding: 40px 0px 0px 0px;
  }
`;

export const SkillsCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  gap: 12px;
  @media ${cssBreakpoint.down("md")} {
    padding: 40px 0px 0px 0px;
  }
`;

export const skillContainerCSS = css``;
