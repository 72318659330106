import { Container } from "../../base/Container/Container";
import { TeamPageCSS } from "./TeamPage.style";
import { Text } from "../../base/Text/Text";
import { Image } from "../../base/Image";
import classNames from "classnames";

import { MouseEvent } from "react";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Button } from "../../base/Button"


export function Yang() {

  const navigate = useNavigate()

  const handleClickPrev = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.team);
  }

  return (
    <Container
      className={TeamPageCSS}
      column
      width="100%"
      height="100%"
      alignItems="center"
    >
    <Button onClick={handleClickPrev}>
     <Image
      style={{ width: "25px", marginLeft: "20px"}}
      src="/assets/arrow-left-55.svg"
      />
    </Button>

      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        <text>Jeehye Yang 양지혜</text>
        <text>CEO, Co-Founder</text>
      </Text>

      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--description`)}
      >
        <text>
        양지혜 대표는 문화콘텐츠산업의 전문가입니다. 
        </text>
        <text>
        IP를 기획제작하고, IP비즈니스를 전개해온 경험이 있습니다. 대통령상 애니메이션상을 수상한 빠삐에친구, 문화체육관광부장관상을 수상한 어린이드라마 출동케이캅를 비롯해 수많은 콘텐츠IP를 기획제작하였습니다. 
        </text>
        <text>
        감기획에서 양지혜 대표는 정보통신기술과 문화산업을 융합할 수 있는 차세대 IP사업을 전개하는데 핵심적인 역할을 수행하고 있습니다. 
        </text>
      </Text>

      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        <text>한국여성캐릭터협회 회장</text>
        <text>성균관대학교 영상학과 겸임교수 역임</text>
        <text>2012 여수엑스포 조직위원회 예술감독 역임</text>
      </Text>

      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        <text>jeehye@pclglobal.xyz</text>
      </Text>





 

    </Container>
  );
}
