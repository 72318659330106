import { ReactNode } from "react";
// import { Container } from "@/base/Container/Container";
import { Container } from "../base/Container/Container";
import { Text } from "../base/Text/Text";
import { Header } from "./Header/Header";

// import { Button } from "../base/Button";
// import { Image } from "../base/Image";

import { mobileFooterSocialCSS } from "./page.style";
import { useMediaQuery } from "react-responsive";

import { cssBreakpoint } from "../styles/theme";

import {
  pageCSS,
  pageBodyCSS,
  FooterSectionCSS,
  FooterLogoCSS,
} from "./page.style";
import { Helmet } from "react-helmet";

function MetaTag() {
  return (
    <Helmet>
      <title> 감기획 | PERSIMMON </title>
    </Helmet>
  );
}

function Footer() {
  const isMD = useMediaQuery({ query: cssBreakpoint.down("md") });

  return (
    <Container className={FooterSectionCSS}>
      {isMD && (
        <Container className={mobileFooterSocialCSS}>
          {/* <Button
            onClick={() => {
              window.open("https://www.instagram.com/u_youha/");
            }}
          >
            <Image
              style={{ width: "25px" }}
              src="/assets/social/instagram.svg"
            />
          </Button> */}
          {/* <Button
            onClick={() => {
              window.open(
                "https://ko-kr.facebook.com/profile.php?id=pfbid034P5r9FnDiTDo1oVikyiU6Hh9hnc31QozJ2LyoFyXrh9dKz6iSocTyktePyVDKR5gl"
              );
            }}
          >
            <Image
              style={{ width: "25px" }}
              src="/assets/social/facebook.svg"
            />
          </Button> */}
        </Container>
      )}

      <Container className={FooterLogoCSS}>
        <Text>CEO: JEEHYE YANG.</Text>
        <Text>COMPANY: PERSIMMON COMPANY.</Text>
        <Text>ADDRESS: 2F, 50, JONG-RO 1-GIL, JONGNO-GU, SEOUL</Text>
        <Text>©감기획/PERSIMMON, 2023. ALL RIGHTS RESERVED.</Text>
      </Container>
    </Container>
  );
}

export interface ComponentBaseProps {
  className?: string | undefined;
  children?: ReactNode;
  id?: string;
  key?: string | number;
}

export interface PageProps extends ComponentBaseProps {}

export function Page({ children }: PageProps) {
  return (
    <>
      <MetaTag />
      <Container className={pageCSS}>
        <Header />
        <section className={pageBodyCSS}>{children}</section>
        <Footer />
      </Container>
    </>
  );
}
