import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Page } from "./layout/Page";
import { HomePage } from "./pages/Home/HomePage";
import { AboutPage } from "./pages/About/AboutPage";
import { TeamPage } from "./pages/Team/TeamPage";
import { Yang } from "./pages/Team/Yang";
import { Ro } from "./pages/Team/Ro";
import { FallbackPage } from "./pages/fallback/FallbackPage";
import { InsightsPage } from "./pages/Insights/InsightsPage";
import { NewsPage } from "./pages/News/NewsPage";

import { routes } from "./routes";
import { GlobalStyles } from "./styles/GlobalStyles";

export function App() {
  return (
    <>
      <GlobalStyles />

      <Page>
        <Suspense fallback={<FallbackPage />}>
          <Routes>
            <Route path={routes.about} element={<AboutPage />} />
            <Route path={routes.insights} element={<InsightsPage />} />
            <Route path={routes.news} element={<NewsPage />} />
            <Route path={routes.team} element={<TeamPage />} />
            <Route path={routes.yang} element={<Yang/>} />
            <Route path={routes.ro} element={<Ro/>} />
            <Route path={`${routes.home}*`} element={<HomePage />} />
          </Routes>
        </Suspense>
      </Page>
    </>
  );
}
