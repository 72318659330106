import { ReactNode } from "react";
import LottieBase, { LottieProps } from "react-lottie";

export interface ComponentBaseProps {
  className?: string | undefined;
  children?: ReactNode;
  id?: string;
  key?: string | number;
}

export interface LottieWrapperProps extends ComponentBaseProps, LottieProps {}

export function LottieWrapper({
  className,
  id,
  key,
  ...props
}: LottieWrapperProps) {
  return (
    <div className={className} id={id} key={key}>
      <LottieBase {...props} />
    </div>
  );
}
