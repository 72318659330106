import classNames from "classnames";
import { forwardRef } from "react";
import { useDirection } from "./Container.context";
import { containerCSS } from "./Container.style";
import { ContainerProps } from "./Container.type";

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      className,
      row,
      column,
      reverse,
      width,
      height,
      gap,
      justifyContent,
      justifySelf,
      justifyItems,
      alignContent,
      alignSelf,
      alignItems,
      ...props
    },
    ref
  ) => {
    const directionCSS = useDirection({ row, column, reverse });

    return (
      <div
        className={classNames(containerCSS, directionCSS, className)}
        css={{
          width,
          height,
          gap,
          justifyContent,
          justifySelf,
          justifyItems,
          alignContent,
          alignSelf,
          alignItems,
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

Container.displayName = "Container";
