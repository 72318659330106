import { useCallback, useEffect, useRef, useState } from "react";

const SCROLL_THRESHOLD = 20;
type ScrollDirection = "up" | "down" | undefined;

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>();

  const blocking = useRef(false);
  const prevScrollY = useRef(0);

  const updateScrollDirection = useCallback(() => {
    const scrollY = window.pageYOffset;
    if (Math.abs(scrollY - prevScrollY.current) >= SCROLL_THRESHOLD) {
      const newScrollDirection = scrollY > prevScrollY.current ? "down" : "up";
      if (scrollY === 0) {
        setScrollDirection(undefined);
      } else {
        setScrollDirection(newScrollDirection);
      }

      prevScrollY.current = scrollY > 0 ? scrollY : 0;
    }

    blocking.current = false;
  }, []);

  const onScroll = () => {
    if (!blocking.current) {
      blocking.current = true;
      window.requestAnimationFrame(updateScrollDirection);
    }
  };

  useEffect(() => {
    updateScrollDirection();
    prevScrollY.current = window.pageYOffset;

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [updateScrollDirection]);

  return scrollDirection;
}
