import { Container } from "../../base/Container/Container";

export function FallbackPage() {
  return (
    <Container>
      <section>
        <h1>FallbackPage</h1>
      </section>
    </Container>
  );
}
