import classNames from "classnames";
import { useImage } from "./Image.context";
import { SVGMaskComponent, SVGContainerCSS } from "./Image.style";
import { ImageProps } from "./Image.type";

export function Image({
  className,
  src,
  width,
  height,
  fallback,
  color,
  onError,
  useMask,
  ...props
}: ImageProps) {
  const { isSVG, handleError } = useImage(src, fallback, onError);

  if (isSVG && useMask) {
    return (
      <div className={classNames(SVGContainerCSS, className)}>
        <SVGMaskComponent url={src} color={color} width={width} height={height} {...props} />
      </div>
    );
  }

  return (
    <img
      className={className}
      css={{ width, height }}
      src={src ?? fallback}
      onError={handleError}
      {...props}
    />
  );
}
