import { css } from "@emotion/css";
import { colorPalette, typography } from "../../styles/theme";

export const desktopHeaderCSS = css`
  width: 100%;
  justify-content: space-between;
`;

export const desktopHeaderContentCSS = css`
  width: 100%;
  padding: 16px 40px;
  justify-content: space-between;
  align-items: center;
`;

export const desktopTabButtonCSS = css`
  ${typography.h6}
  color: ${colorPalette.overBlack};

  &--selected {
    color: ${colorPalette.overOrange};
  }

  :disabled {
    cursor: unset;
    color: ${colorPalette.overGray400};
  }
`;

export const headerCollapseContainer = css`
  width: 100%;
  background-color: ${colorPalette.overWhite};
  z-index: 1000;
`;

export const desktopHeaderLogoCSS = css`
  width: 250px;
  justify-content: flex-start;
`;
