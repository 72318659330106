import { Container } from "../../base/Container/Container";
import { InsightsPageCSS, ComingSoonCSS } from "./InsightsPage.style";
import { Text } from "../../base/Text/Text";
// import classNames from "classnames";

export function InsightsPage() {
  return (
    <Container
      className={InsightsPageCSS}
      column
      width="100%"
      height="100%"
      alignItems="center"
    >
      <Text
        className={ComingSoonCSS}
        style={{ color: "black", fontWeight: "600" }}
      >
        Coming Soon
      </Text>
    </Container>
  );
}
