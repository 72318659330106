import { MouseEvent } from "react";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Container } from "../../base/Container/Container";
import { TeamPageCSS } from "./TeamPage.style";
import { Text } from "../../base/Text/Text";
import { Button } from "../../base/Button";
import classNames from "classnames";

export function TeamPage() {
  const navigate = useNavigate();

  const handleClickYang = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.yang);
  };

  // const handleClickRo = (e: MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   navigate(routes.ro);
  // };

  return (
    <Container
      className={TeamPageCSS}
      column
      width="100%"
      height="100%"
      alignItems="center"
    >
      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        감기획의 경영진을 소개합니다.
      </Text>

      <Container
        className={classNames(
          TeamPageCSS,
          `${TeamPageCSS}--portrait-container`
        )}
      >
        <Button
          className={classNames(TeamPageCSS, `${TeamPageCSS}--portrait`)}
          onClick={handleClickYang}
        >
          <Text
            className={classNames(
              TeamPageCSS,
              `${TeamPageCSS}--portrait-title`
            )}
            style={{ color: "black", fontWeight: "600" }}
          >
            <text>JEEHYE YANG</text>
            <text>양지혜</text>
          </Text>
        </Button>

        {/* <Button className={classNames(TeamPageCSS, `${TeamPageCSS}--portrait`)} onClick={handleClickRo}>
      <Text
        className={classNames(TeamPageCSS, `${TeamPageCSS}--portrait-title`)}
        style={{ color: "black", fontWeight: "600" }}
      >
        <text>JAEHUN RO</text>
        <text>노재헌</text>
      </Text>
    </Button> */}
      </Container>
    </Container>
  );
}
