import { MouseEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button } from "../../base/Button";
import { Container } from "../../base/Container";
import { Image } from "../../base/Image";
// import { Text } from "../../base/Text";
import {
  mobileHeaderContentCSS,
  mobileHeaderCSS,
  mobileHeaderTitleCSS,
  mobileHeaderLogoCSS,
  mobileHeaderWrapperCSS,
  mobileHeaderButtonCSS,
} from "./MobileHeader.style";
import CancelAnimation from "../../assets/lottie/cancel.json";
import MenuAnimation from "../../assets/lottie/menu.json";

import { LottieWrapper } from "../../base/Lottie";
import { routes } from "../../routes";
import { isMobileHeaderOpen } from "../../state/atom";
import { Tab } from "../../types/header";

export async function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const hideMobileHeaderMenu = async () => {
  const mobileHeaderContentEl = window.document.getElementsByClassName(
    mobileHeaderContentCSS
  )[0];
  if (mobileHeaderContentEl) {
    mobileHeaderContentEl.setAttribute(
      "class",
      `${mobileHeaderContentCSS} ${mobileHeaderContentCSS}--hide`
    );
  }
  return wait(400);
};

function useMobileHeader() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<number>();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMobileHeaderOpen);

  const handleClickMainLogo = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.home);
    if (isMenuOpen) closeMenu();
  };

  const handleClickTab = (i: number) => {
    if (i === selectedTab && isOpened) {
      setIsOpened(false);
    } else {
      setSelectedTab(i);
      setIsOpened(true);
    }
  };

  const closeMenu = useCallback(async () => {
    await hideMobileHeaderMenu();
    setIsMenuOpen(false);
  }, []);

  const handleClickAbout = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.about);
    isMenuOpen ? closeMenu() : setIsMenuOpen(!isMenuOpen);
  };

  const handleClickTeam = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.team);
    isMenuOpen ? closeMenu() : setIsMenuOpen(!isMenuOpen);
  };

  const handleClickInsights = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.insights);
    isMenuOpen ? closeMenu() : setIsMenuOpen(!isMenuOpen);
  };

  const handleClickNews = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routes.news);
    isMenuOpen ? closeMenu() : setIsMenuOpen(!isMenuOpen);
  };

  return {
    selectedTab,
    isOpened,
    isMenuOpen,
    setIsMenuOpen,
    navigate,
    handleClickMainLogo,
    handleClickTab,
    closeMenu,
    handleClickAbout,
    handleClickTeam,
    handleClickInsights,
    handleClickNews,
  };
}

interface MobileHeaderProps {
  tabList: Tab[];
}

export function MobileHeader({ tabList }: MobileHeaderProps) {
  const {
    isMenuOpen,
    setIsMenuOpen,
    handleClickMainLogo,
    closeMenu,
    handleClickAbout,
    handleClickTeam,
    handleClickInsights,
    handleClickNews,
  } = useMobileHeader();

  console.log(tabList);

  return (
    <Container column className={mobileHeaderWrapperCSS}>
      <Container column className={mobileHeaderCSS} alignItems="center">
        <Container className={mobileHeaderTitleCSS}>
          <Button onClick={handleClickMainLogo}>
            <Image className={mobileHeaderLogoCSS} src="/assets/Logo1.svg" />
          </Button>
          <Button
            onClick={() =>
              isMenuOpen ? closeMenu() : setIsMenuOpen(!isMenuOpen)
            }
            width="30px"
            height="30px"
          >
            {isMenuOpen ? (
              <LottieWrapper
                options={{ animationData: MenuAnimation, loop: false }}
                style={{ display: "flex", alignItems: "center" }}
                width="30px"
                height="30px"
              />
            ) : (
              <LottieWrapper
                options={{ animationData: CancelAnimation, loop: false }}
                style={{ display: "flex", alignItems: "center" }}
                width="30px"
                height="30px"
              />
            )}
          </Button>
        </Container>
      </Container>
      {isMenuOpen && (
        <Container
          column
          className={mobileHeaderContentCSS}
          justifyContent="space-between"
        >
          <Container column width="100%">
            <Container className={mobileHeaderButtonCSS}>
              <Button
                style={{ fontWeight: "600", fontSize: "20px" }}
                onClick={handleClickAbout}
              >
                About
              </Button>
            </Container>

            <Container className={mobileHeaderButtonCSS}>
              <Button
                style={{ fontWeight: "600", fontSize: "20px" }}
                onClick={handleClickTeam}
              >
                Team
              </Button>
            </Container>

            <Container className={mobileHeaderButtonCSS}>
              <Button
                style={{ fontWeight: "600", fontSize: "20px" }}
                onClick={handleClickInsights}
              >
                Insights
              </Button>
            </Container>

            <Container className={mobileHeaderButtonCSS}>
              <Button
                style={{ fontWeight: "600", fontSize: "20px" }}
                onClick={handleClickNews}
              >
                News
              </Button>
            </Container>
          </Container>
        </Container>
      )}
    </Container>
  );
}
