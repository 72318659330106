import classNames from "classnames";
import { useMemo } from "react";
import { DirectionProps } from "./Container.type";

export function useDirection({ row, column, reverse }: DirectionProps) {
  return useMemo(
    () => classNames(row && "row", column && "column", reverse && "reverse"),
    [row, column, reverse]
  );
}
