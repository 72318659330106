import { css } from "@emotion/css";
import { cssBreakpoint } from "../../styles/theme";

export const NewsPageCSS = css`
  width: 100%;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
    padding: 40px 20px 40px 20px;
  }
`;

export const ComingSoonCSS = css`
  font-size: 100px;
  @media ${cssBreakpoint.down("md")} {
    font-size: 50px;
  }
`;

export const EducationCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
  }
`;

export const InformationCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
  }
`;

export const WorkExperienceCSS = css`
  width: 100%;
  max-width: 960px;
  padding: 40px 40px;
  color: #8f8f8f;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;
  @media ${cssBreakpoint.down("md")} {
  }
`;
