import { keyframes } from "@emotion/react";


export const typewriter = keyframes`
to{
    left: 100%;
}
`


export const moveUp = keyframes`
0% {
  opacity: 0;
  transform: translate3d(0px, 50px, 0px);
}

100% {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
`;

export const moveLeft = keyframes`
0% {
  opacity: 0;
  transform: translate3d(50px, 0px, 0px);
}

100% {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
`;

export const moveRight = keyframes`
0% {
  opacity: 0;
  transform: translate3d(-50px, 0px, 0px);
}

100% {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
`;

export const moveLeftIn = keyframes`
0% {
  transform: translate3d(100vw, 0px, 0px);
}

100% {
  transform: translate3d(0px, 0px, 0px);
}
`;

export const moveRightOut = keyframes`
0% {
  transform: translate3d(0px, 0px, 0px);
}

100% {
  transform: translate3d(100vw, 0px, 0px);
}
`;