export const routes: { [key: string]: string } = {
  about: "/about",
  team: "/team",
  yang: "/team/yang",
  ro: "/team/ro",
  insights: "/insights",
  news: "/news",
  Map: "/map",
  home: "/",
};
