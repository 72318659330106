import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react";



export function isString(t: any): t is string {
  return typeof t === "string";
}

export function isBreakpoint(t: any): t is Breakpoint {
  return isString(t) && ReservedBreakpoint.includes(t as Exclude<Breakpoint, number>);
}

export type MediaQuery = "up" | "down" | "between";

function buildUp(value: number) {
  return `(min-width:${value}px)`;
}

function buildDown(value: number, step = 5) {
  return `(max-width:${value - step / 100}px)`;
}

function buildBetween(start: number, end: number, step = 5) {
  return `(min-width:${start}px) and (max-width:${end - step / 100}px)`;
}

export class CSSBreakpoints {
    constructor(public readonly breakpoints: Breakpoints) {}
  
    up(key: keyof Breakpoints | string) {
      return buildUp(isBreakpoint(key) ? this.breakpoints[key] : Number(key));
    }
  
    down(key: keyof Breakpoints | string) {
      return buildDown(isBreakpoint(key) ? this.breakpoints[key] : Number(key));
    }
  
    between(from: keyof Breakpoints | string, to: keyof Breakpoints | string) {
      return buildBetween(
        isBreakpoint(from) ? this.breakpoints[from] : Number(from),
        isBreakpoint(to) ? this.breakpoints[to] : Number(to)
      );
    }
  }


const ReservedTypography = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "h7",
    "h8",
  
    "b1",
    "b2",
    "b3",
    "b4",
    "b5",
  
    "c1",
    "c2",
  ] as const;
  
  export type Typography = typeof ReservedTypography[number];
  
  export type TypographyStyles = {
    [key in Typography]: SerializedStyles;
  };


export const ReservedBreakpoint = ["xs", "sm", "md", "lg", "xl", "mobile", "tablet", "pc"] as const;

export type Breakpoint = typeof ReservedBreakpoint[number] | number;

export type Breakpoints = {
  [key in Breakpoint]: number;
};

export const ReservedColors = [
    "overOrange",
    "overBlack",
    "overWhite",
  
    "overGray50",
    "overGray75",
    "overGray100",
    "overGray200",
    "overGray300",
    "overGray400",
    "overGray500",
    "overGray600",
    "overGray700",
    "overGray800",
  
    "overOrange25",
    "overOrange50",
    "overOrange100",
    "overOrange200",
    "overOrange300",
    "overOrange400",
    "overOrange500",
    "overOrange600",
    "overOrange700",
    "overOrange800",
  
    "overGreen25",
    "overGreen50",
    "overGreen100",
    "overGreen200",
    "overGreen300",
    "overGreen400",
    "overGreen500",
    "overGreen600",
    "overGreen700",
    "overGreen800",
  
    "blue50",
    "blue100",
    "blue200",
    "blue300",
    "blue400",
    "blue500",
    "blue600",
    "blue700",
    "blue800",
  
    "red25",
    "red50",
    "red100",
    "red200",
    "red300",
    "red400",
    "red500",
    "red600",
    "red700",
    "red800",
  ] as const;
  
  export type ThemeColor = typeof ReservedColors[number] | (string & {});
  
  export type ColorPalette = {
    [key in ThemeColor]: string;
  };

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 360,
  md: 768,
  lg: 1440,
  xl: 1920,
  mobile: 360,
  tablet: 906,
  pc: 1078,
};

export const cssBreakpoint = new CSSBreakpoints(breakpoints);

export const colorPalette: ColorPalette = {
  overOrange: "#ff881a",
  overBlack: "#000",
  overWhite: "#fff",

  overGray50: "#F5F5F5",
  overGray75: "#EBEBEB",
  overGray100: "#DBDBDB",
  overGray200: "#C2C2C2",
  overGray300: "#A8A8A8",
  overGray400: "#8F8F8F",
  overGray500: "#757575",
  overGray600: "#5C5C5C",
  overGray700: "#424242",
  overGray800: "#292929",

  overOrange25: "#ffeedf",
  overOrange50: "#ffd9b6",
  overOrange100: "#ffc48d",
  overOrange200: "#ffaf65",
  overOrange300: "#ff9a3c",
  overOrange400: "#ff881a",
  overOrange500: "#e97100",
  overOrange600: "#d45006",
  overOrange700: "#b83308",
  overOrange800: "#8e1f07",

  overGreen25: "#EAFAF2",
  overGreen50: "#D3FDE8",
  overGreen100: "#97E5BF",
  overGreen200: "#6EDBA6",
  overGreen300: "#45D18E",
  overGreen400: "#2DB674",
  overGreen500: "#28A267",
  overGreen600: "#238D5A",
  overGreen700: "#196440",
  overGreen800: "#0F3B26",

  blue50: "#F1F5FF",
  blue100: "#C0D1FD",
  blue200: "#8EADFC",
  blue300: "#5D88FA",
  blue400: "#2B64F9",
  blue500: "#0746EA",
  blue600: "#0537B9",
  blue700: "#042887",
  blue800: "#062d93",

  red25: "#FFF7F7",
  red50: "#FFEDED",
  red100: "#FFD3D3",
  red200: "#FFBABA",
  red300: "#FF8787",
  red400: "#FF5454",
  red500: "#ED0000",
  red600: "#BA0000",
  red700: "#870000",
  red800: "#3A0000",
};

export const typography: TypographyStyles = {
  h1: css`
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    font-weight: 700;
  `,
  h2: css`
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.02em;
    font-weight: 700;
  `,
  h3: css`
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    font-weight: 700;
  `,
  h4: css`
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
    font-weight: 700;
  `,
  h5: css`
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    font-weight: 700;
  `,
  h6: css`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    font-weight: 700;
  `,
  h7: css`
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    font-weight: 700;
  `,
  h8: css`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    font-weight: 700;
  `,

  b1: css`
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    font-weight: 500;
  `,
  b2: css`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    font-weight: 500;
  `,
  b3: css`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-weight: 500;
  `,
  b4: css`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    font-weight: 500;
  `,
  b5: css`
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.02em;
    font-weight: 500;
  `,

  c1: css`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    font-weight: 400;
  `,
  c2: css`
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.02em;
    font-weight: 400;
  `,
};
