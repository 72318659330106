import { Container } from "../../base/Container/Container";
import { NewsPageCSS, ComingSoonCSS } from "./NewsPage.style";
import { Text } from "../../base/Text/Text";
// import classNames from "classnames";

export function NewsPage() {
  return (
    <Container
      className={NewsPageCSS}
      column
      width="100%"
      height="100%"
      alignItems="center"
    >
      <Text
        className={ComingSoonCSS}
        style={{ color: "black", fontWeight: "600" }}
      >
        Coming Soon
      </Text>
    </Container>
  );
}
